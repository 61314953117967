<template>
  <div>
    <b-card class="text-center">
      <h1><feather-icon
        class="mr-2"
        icon="ShoppingBagIcon"
        size="25"
      />Richieste Divisa da consegnare</h1>
    </b-card>
    <!-- Filters -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostra</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>risultati</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUniformsListTable"
        class="position-relative"
        :items="fetchUniforms"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nessuna Richiesta di Divisa da consegnare"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: ID -->
        <template #cell(id)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">#{{ data.item.id }}</span>
          </div>
        </template>

        <template #cell(utente)="data">
          <div class="text-nowrap">
            <div class="d-flex align-items-center">
              <b-avatar
                size="25"
                :src="data.item.user.avatar_file ? mediaUrl + data.item.user.avatar_file : ''"
                :text="avatarText(data.item.user.display_name)"
                :variant="`light-${resolveUserRoleVariant(data.item.user.role_code)}`"
                class="mr-1"
              />
              <b-link>
                #{{ data.item.user.anagrafico_id }} - {{ data.item.user.display_name }}
              </b-link>
            </div>
          </div>
        </template>

        <template #cell(reparto)="data">
          <div
            v-if="data.item.department"
            class="text-nowrap"
          >
            <div class="d-flex align-items-center">
              <b-link>
                #{{ data.item.department.id }} - {{ data.item.department.name }}
              </b-link>
            </div>
          </div>
        </template>

        <template #cell(creato)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ parseDate(data.item.created_at) }}</span>
          </div>
        </template>

        <template #cell(approvato)="data">
          <div class="text-nowrap">
            <span class="align-text-top text-capitalize">{{ parseDate(data.item.approved_at) }}</span>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(azioni)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-uniformsreception-view', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Dettagli</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando da {{ dataMeta.from }} a {{ dataMeta.to }} di {{ dataMeta.of }} risultati</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUniforms"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BAvatar,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import useUniformsList from './useUniformsList'
import uniformsStoreModule from '../uniformsStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAvatar,
    BLink,
    vSelect,
  },
  setup() {
    const UNIFORMS_APP_STORE_MODULE_NAME = 'app-uniforms'

    // Register module
    if (!store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(UNIFORMS_APP_STORE_MODULE_NAME, uniformsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(UNIFORMS_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(UNIFORMS_APP_STORE_MODULE_NAME)
      }
    })

    const mediaUrl = process.env.VUE_APP_MEDIA_URL

    const {
      fetchUniforms,
      tableColumns,
      perPage,
      currentPage,
      totalUniforms,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUniformsListTable,
      parseDate,
      refetchData,
      resolveUserRoleVariant,
    } = useUniformsList()

    return {
      fetchUniforms,
      tableColumns,
      perPage,
      currentPage,
      totalUniforms,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refUniformsListTable,
      parseDate,
      refetchData,
      resolveUserRoleVariant,
      mediaUrl,
      avatarText,
      mainProps: {
        width: 100,
        height: 50,
      },
      blankProps: {
        blank: true,
        blankColor: '#777',
        width: 100,
        height: 50,
      },
      selectedUniform: null,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vehicle_list_img {
  object-fit: cover;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
